<template lang="">
  <div class="pb-2">
    <SearchETicketPage
      source="VN1A"
      :header="$t('reservation.viewEticket_VN1A_QH_1G')"
    />

    <SearchShortenBooking />

    <SendOutsideEmail />
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'

import store from '@/store'

import reservationStoreModule from '@reservation/reservationStoreModule'

export default {
  components: {
    SearchETicketPage: () => import('./SearchETicketPage.vue'),
    SearchShortenBooking: () => import('./SearchShortenBooking.vue'),
    SendOutsideEmail: () => import('./SendOutsideEmail.vue'),
  },
  setup() {
    const RESERVATION_APP_STORE_MODULE_NAME = 'app-reservation'
    if (!store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
      store.registerModule(RESERVATION_APP_STORE_MODULE_NAME, reservationStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESERVATION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(RESERVATION_APP_STORE_MODULE_NAME)
      }
    })
  },
}
</script>
<style lang="">

</style>
